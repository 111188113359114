/* I hate CSS */

@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');

:root {
  --color-main: #ccc;
  --color-secondary: #8e9297;
  --color-bg-primary: #1e1e1e;
  --color-bg-secondary: #181818;
  --box-shadow: 0 0 5px black;
  --box-shadow-br: 1px 2px 5px black;
  --color-accent: #4f5d7e;
}

body[color="2"] {
  --color-accent: #75903f;
}

body[color="3"] {
  --color-accent: #e7964b;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(.95)
  }

  to {
    opacity: 1;
    transform: scale(1)
  }
}

*, html, body {
  margin: 0;
  padding: 0;
}

*[hidden] {
  display: none !important;
}

::selection {
  background: var(--color-accent);
}

#container {
  background-color: var(--color-bg-primary);
  color: var(--color-main);
  font-family: 'Raleway', sans-serif;
  font-size: 1.5em;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#main-section {
  overflow-y: auto;
  flex: 1;
  background: url("./res/img/bg.png");
}


h1, button {
  font-family: 'Roboto', sans-serif;
}

button {
  color: var(--color-main);
  font-size: 1em;
  background: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
}

h1 {
  font-weight: normal;
}

nav {
  top: 0;
  left: 0;
  z-index: 9001;
  width: 100%;
  box-shadow: var(--box-shadow-small);
  height: var(--header-height);
  display: flex;
  position: relative;
}

nav:after {
  content: '';
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  background: url('./res/img/cact.svg');
  z-index: 9002;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  right: .5em;
  bottom: 2px;
  opacity: 0.05;
  transition: .4s;
}

nav:hover:after {
  transform: scaleX(-1);
}

nav, footer {
  box-shadow: var(--box-shadow);
  background: var(--color-bg-secondary);
}

nav>* {
  flex-basis: 5em;
}

nav button {
  padding: .5em 0;
  width: 100%;
}

footer {
  display: flex;
  justify-content: space-between;
  padding: .2rem 1rem;
  font-size: .6em;
}

.external {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-main);
  padding: .4em .8em;
  margin-top: .2em;
}

.external>button {
  width: 1em;
  height: 1em;
  background-size: contain;
  margin-left: .2em;
  opacity: 0.5;
  transition: 0.1s;
}

.external>button:hover {
  opacity: 1;
  transform: scale(1.05);
}

.external>button[title="GitHub"] {
  background-image: url("res/img/github.svg");
}

.external>button[title="LinkedIn"] {
  background-image: url("res/img/linkedin.svg");
}

#img-me {
  width: 30vh;
  height: 30vh;
  position: relative;
  background: url('res/img/me.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: var(--box-shadow-br);
}

.page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  flex-wrap: wrap;
  animation: grow .3s ease-in-out;
}

#home-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 0.4em;
}

#home-content {
  padding: 1rem 2rem;
  border-radius: 5px;
  background: var(--color-bg-secondary);
  border-left: 5px solid var(--color-accent);
  box-shadow: var(--box-shadow-br);
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-container {
  width: 60vw;
  padding: 1em 2em 3em 2em;
  border-radius: 5px;
  background: var(--color-bg-secondary);
  border-left: 5px solid var(--color-accent);
  box-shadow: var(--box-shadow-br);
}

#about-container h1 {
  border-bottom: 1px solid var(--color-main);
}

#data-about>p {
  margin-top: .6em;
  color: var(--color-secondary);
}

#projects-container {
  height: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  margin: 0 1em;
}

#data-projects {
  overflow-y: auto;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 5px;
}

.project-container {
  border-radius: 5px;
  padding: .5em 1em 1em 1em;
  background: var(--color-bg-secondary);
  font-size: 0.7em;
  transform: scale(0.95);
  transition: 0.1s;
  transform-origin: left;
  border-left: 5px solid var(--color-accent);
  box-shadow: var(--box-shadow-br);
  position: relative;
}

.project-container:after {
  position: absolute;
  content: attr(data-emoji);
  bottom: .2em;
  right: .2em;
  opacity: 0.2;
  font-size: 4em;
}

.project-container:hover {
  transform: scale(1);
}

#projects-container>div>h1 {
  padding-bottom: 0.2em;
}

.features, .project-resources {
  display: flex;
  gap: .4em;
  margin: .2em 0;
  flex-wrap: wrap;
}

.features>div {
  padding: 0 .4em;
  background: var(--color-main);
  color: var(--color-bg-primary);
  border-radius: 4px;
}

.last-updated {
  color: var(--color-secondary);
  font-size: 0.8em;
  padding-top: 0.4em;
}

.resume-container {

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  margin: auto;
  margin-bottom: 1em;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  border: 0.8em solid #cccccc55;
  border-right: 0.8em solid #ccc;
  animation: spin 1s infinite linear;
}

.resume-redirect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-resources>button, .resume-redirect>button {
  border: 2px solid var(--color-main);
  padding: 0 .4em;
  transition: 0.1s;
  border-radius: 4px;
}

.project-resources>button:hover, .resume-redirect>button:hover {
  background: var(--color-main);
  color: var(--color-bg-primary)
}

nav button:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleY(0);
  transform-origin: bottom;
  background: var(--color-accent);
  transition: 0.1s;
}

nav button[data-active]:after, nav button:hover:after {
  transform: scaleY(1);
}

nav button[data-active], nav button:hover {
  background: var(--color-bg-primary);
}

.dot {
  position: absolute;
  width: .5em;
  height: .5em;
  border-radius: 50%;
  box-shadow: var(--box-shadow-br);
  cursor: pointer;
  transition: 0.1s;
  opacity: 0.3;
  top: 50%;
  left: 50%;
}

.dot[data-active], .dot:hover {
  opacity: 1;
}

.dot-1 {
  background-color: #4f5d7e;
  transform: translateY(-50%) rotate(45deg) translateX(calc(15vh + 0.35em));
}

.dot-1[data-active], .dot-1:hover {
  transform: translateY(-50%) rotate(45deg) translateX(calc(15vh + 0.35em)) scale(1.1);
}

.dot-2 {
  background-color: #75903f;
  transform: translateY(-50%) rotate(55deg) translateX(calc(15vh + 0.35em));
}

.dot-2[data-active], .dot-2:hover {
  transform: translateY(-50%) rotate(55deg) translateX(calc(15vh + 0.35em)) scale(1.1);
}

.dot-3 {
  background-color: #e7964b;
  transform: translateY(-50%) rotate(35deg) translateX(calc(15vh + 0.35em));
}

.dot-3[data-active], .dot-3:hover {
  transform: translateY(-50%) rotate(35deg) translateX(calc(15vh + 0.35em)) scale(1.1);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-bg-secondary);
}

::-webkit-scrollbar-thumb {
  background: var(--color-main);
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 1em;
  }

  .page {
    font-size: 1em;
  }

  #home-container {
    margin: 1em;
    font-size: 1.5em;
  }

  #about-container {
    width: unset;
    margin: 1em;
  }

  .project-container {
    transform: unset;
  }

  .project-container p, .features, .project-container button {
    font-size: 1.5em;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}